.Nav {
  display: flex;
  position: relative;
  height: 12vh;
  width: 100vw;
  max-width: 100%;
  background-color: #c80d25;
  align-items: center;
}

.Nav h1 {
  color: white;
  margin-left: 1rem;
}

.Nav button {
  position: absolute;
  right: 2%;
  height: 5vh;
  width: 8rem;
  background-color: white;
  border-radius: 10px;
  color: #c80d25;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.nav-collap {
  position: absolute;
  display: flex;
  right: 15%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.nav-collap ul li {
  display: inline;
  list-style: none;
  color: white;
  font-size: 1rem;
  margin-left: 0.9rem;
}

h2 {
  color: white;
  text-decoration: none;
  margin-left: 1rem;
  font-size: 1.2rem;
}

a {
  text-decoration: none !important;
}
