.contact-container h1 {
  font-family: "Gobold Hollow Bold";
  font-size: 5rem;
  margin-top: 2rem;
  font-weight: lighter;
  color: #c80d25;
  text-align: center;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
}

.contact-table {
  width: 90vw;
}
