.Loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

h1 {
  font-family: "Gobold Hollow Bold";
  font-size: 5rem !important;
  margin-top: 2rem !important;
  font-weight: lighter !important;
  color: #c80d25 !important;
  text-align: center !important;
}

@font-face {
  font-family: "Gobold Hollow Bold";
  src: local("Gobold Hollow Bold"),
    url("./fonts/Gobold Hollow Bold.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Gobold Hollow Bold Italic";
  src: local("Gobold Hollow Bold Italic"),
    url("./fonts/Gobold Hollow Bold Italic.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-SemiboldItalic";
  src: local("Eina02-SemiboldItalic"),
    url("./fonts/Eina02-SemiboldItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-SemiBold";
  src: local("Eina02-SemiBold"),
    url("./fonts/Eina02-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-RegularItalic";
  src: local("Eina02-RegularItalic"),
    url("./fonts/Eina02-RegularItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-Regular";
  src: local("Eina02-Regular"),
    url("./fonts/Eina02-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-Light";
  src: local("Eina02-Light"), url("./fonts/Eina02-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-LightItalic";
  src: local("Eina02-LightItalic"),
    url("./fonts/Eina02-LightItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Benguiat";
  src: local("BENGUIAT"), url("./fonts/BENGUIAT.TTF") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-Bold";
  src: local("Eina02-Bold"), url("./fonts/Eina02-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-BoldItalic";
  src: local("Eina02-BoldItalic"),
    url("./fonts/Eina02-BoldItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "AbhayaLibre-ExtraBold.ttf";
  src: local("AbhayaLibre-ExtraBold.ttf"),
    url("./fonts/AbhayaLibre-ExtraBold.ttf") format("truetype");
  font-weight: normal;
}
