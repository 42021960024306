.login-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 4rem;
  width: 12rem;
  border: none;
  background-color: white;
  color: #c80d25;
  font-size: 1rem;
}

.login-bg {
  overflow: hidden;
  margin: 0;
  box-sizing: border-box;
  background-color: #c80d25 !important;
  height: 100vh;
}

div#tsparticles {
  height: 100% !important;
}

.carousel-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

td img {
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 2rem !important;
  justify-content: center !important;
  border-bottom: 2px solid #c80d25 !important;
}

#img-form {
  display: block;
  height: 2.5rem;
  width: 18rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

#sub-img {
  height: 2.2rem;
  width: 13rem;
  border: none;
  border: 2px solid #c80d25;
  color: #c80d25;
  background-color: white;
  margin: 1rem;
}

#sub-img:hover {
  border: 2px solid #c80d25;
  background-color: #c80d25;
  color: white;
  margin: 1rem;
  font-weight: bold;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #c80d25 !important;
  color: white !important;
}

.nav-tabs .nav-link {
  color: #c80d25 !important;
}

.flex-center-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
