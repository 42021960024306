@import url("http://fonts.cdnfonts.com/css/san-marino-beach");
@import url("http://fonts.cdnfonts.com/css/neoneon");

.about-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.about-container h1 {
  font-family: "Gobold Hollow Bold";
  font-size: 5rem;
  margin-top: 2rem;
  font-weight: lighter;
  color: #c80d25;
  text-align: center;
}

.about-container textarea {
  height: 40vh;
  width: 40vw;
  font-size: 1.2rem;
  text-align: justify;
  padding: 1rem;
}

.about-container .red-btn {
  background-color: #c80d25;
  border: none;
  color: white;
  font-size: 1rem;
  height: 4rem;
  width: 10%;
  display: inline;
  margin: 2rem;
  cursor: pointer;
}

.about-container .flex-row {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
