@import url(http://fonts.cdnfonts.com/css/san-marino-beach);
@import url(http://fonts.cdnfonts.com/css/neoneon);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Loading {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
}

h1 {
  font-family: "Gobold Hollow Bold";
  font-size: 5rem !important;
  margin-top: 2rem !important;
  font-weight: lighter !important;
  color: #c80d25 !important;
  text-align: center !important;
}

@font-face {
  font-family: "Gobold Hollow Bold";
  src: local("Gobold Hollow Bold"),
    url("/static/media/Gobold Hollow Bold.fdf526fd.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Gobold Hollow Bold Italic";
  src: local("Gobold Hollow Bold Italic"),
    url("/static/media/Gobold Hollow Bold Italic.029a1340.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-SemiboldItalic";
  src: local("Eina02-SemiboldItalic"),
    url(/static/media/Eina02-SemiboldItalic.52282586.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-SemiBold";
  src: local("Eina02-SemiBold"),
    url(/static/media/Eina02-SemiBold.c5f9b8e3.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-RegularItalic";
  src: local("Eina02-RegularItalic"),
    url(/static/media/Eina02-RegularItalic.ef56578d.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-Regular";
  src: local("Eina02-Regular"),
    url(/static/media/Eina02-Regular.2e682693.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-Light";
  src: local("Eina02-Light"), url(/static/media/Eina02-Light.96235290.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-LightItalic";
  src: local("Eina02-LightItalic"),
    url(/static/media/Eina02-LightItalic.b5152596.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Benguiat";
  src: local("BENGUIAT"), url(/static/media/BENGUIAT.4f0eb91c.TTF) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-Bold";
  src: local("Eina02-Bold"), url(/static/media/Eina02-Bold.f8011405.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina02-BoldItalic";
  src: local("Eina02-BoldItalic"),
    url(/static/media/Eina02-BoldItalic.60ed7f0e.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "AbhayaLibre-ExtraBold.ttf";
  src: local("AbhayaLibre-ExtraBold.ttf"),
    url(/static/media/AbhayaLibre-ExtraBold.75699413.ttf) format("truetype");
  font-weight: normal;
}

.login-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 4rem;
  width: 12rem;
  border: none;
  background-color: white;
  color: #c80d25;
  font-size: 1rem;
}

.login-bg {
  overflow: hidden;
  margin: 0;
  box-sizing: border-box;
  background-color: #c80d25 !important;
  height: 100vh;
}

div#tsparticles {
  height: 100% !important;
}

.carousel-table {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
}

td img {
  cursor: pointer;
}

form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 2rem !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  border-bottom: 2px solid #c80d25 !important;
}

#img-form {
  display: block;
  height: 2.5rem;
  width: 18rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

#sub-img {
  height: 2.2rem;
  width: 13rem;
  border: none;
  border: 2px solid #c80d25;
  color: #c80d25;
  background-color: white;
  margin: 1rem;
}

#sub-img:hover {
  border: 2px solid #c80d25;
  background-color: #c80d25;
  color: white;
  margin: 1rem;
  font-weight: bold;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #c80d25 !important;
  color: white !important;
}

.nav-tabs .nav-link {
  color: #c80d25 !important;
}

.flex-center-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Nav {
  display: -webkit-flex;
  display: flex;
  position: relative;
  height: 12vh;
  width: 100vw;
  max-width: 100%;
  background-color: #c80d25;
  -webkit-align-items: center;
          align-items: center;
}

.Nav h1 {
  color: white;
  margin-left: 1rem;
}

.Nav button {
  position: absolute;
  right: 2%;
  height: 5vh;
  width: 8rem;
  background-color: white;
  border-radius: 10px;
  color: #c80d25;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.nav-collap {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  right: 15%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav-collap ul li {
  display: inline;
  list-style: none;
  color: white;
  font-size: 1rem;
  margin-left: 0.9rem;
}

h2 {
  color: white;
  text-decoration: none;
  margin-left: 1rem;
  font-size: 1.2rem;
}

a {
  text-decoration: none !important;
}

.about-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.about-container h1 {
  font-family: "Gobold Hollow Bold";
  font-size: 5rem;
  margin-top: 2rem;
  font-weight: lighter;
  color: #c80d25;
  text-align: center;
}

.about-container textarea {
  height: 40vh;
  width: 40vw;
  font-size: 1.2rem;
  text-align: justify;
  padding: 1rem;
}

.about-container .red-btn {
  background-color: #c80d25;
  border: none;
  color: white;
  font-size: 1rem;
  height: 4rem;
  width: 10%;
  display: inline;
  margin: 2rem;
  cursor: pointer;
}

.about-container .flex-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100vw;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.contact-container h1 {
  font-family: "Gobold Hollow Bold";
  font-size: 5rem;
  margin-top: 2rem;
  font-weight: lighter;
  color: #c80d25;
  text-align: center;
}

.contact-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  max-width: 100vw;
}

.contact-table {
  width: 90vw;
}

.product-container input {
  width: 20%;
  height: 6vh;
  border: 1px solid lightgray;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

select {
  height: 45px;
  width: 21rem;
  margin-bottom: 1rem;
  display: block;
}

.flex-col-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#sub-btn-red {
  background-color: #c80d25;
  color: white;
  font-size: 1.1rem;
  width: 15rem;
  height: 45px;
  border: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.product-container .product-add-btn {
  width: 20%;
  height: 6vh;
  border: 1px solid lightgray;
  margin-bottom: 1rem;
  padding-left: 1rem;
  background-color: #c80d25;
  color: white;
  font-size: 1.2rem;
}

