.product-container input {
  width: 20%;
  height: 6vh;
  border: 1px solid lightgray;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

select {
  height: 45px;
  width: 21rem;
  margin-bottom: 1rem;
  display: block;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#sub-btn-red {
  background-color: #c80d25;
  color: white;
  font-size: 1.1rem;
  width: 15rem;
  height: 45px;
  border: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.product-container .product-add-btn {
  width: 20%;
  height: 6vh;
  border: 1px solid lightgray;
  margin-bottom: 1rem;
  padding-left: 1rem;
  background-color: #c80d25;
  color: white;
  font-size: 1.2rem;
}
